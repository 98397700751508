<template>
  <div class="task-post-control-tabs">
    <avatar
      class="is-hidden-mobile"
      :name="$store.getters['user/name']()"
      :avatar="$store.getters['user/avatarUrl']()"
      :size="35"
    />

    <div class="tabs">
      <ul>
        <li
          v-for="(tab, index) in filteredTabs"
          :key="`task-messages-tab-${index}`"
          :class="{ 'is-active': activeTab === index }"
        >
          <a role="button" @click="activeTab = index">{{ tab.label }}</a>
        </li>
      </ul>
    </div>

    <task-post-controls
      ref="taskPostControls"
      :task-id="taskId"
      v-bind="filteredTabs[activeTab].props"
    />
  </div>
</template>

<script>
export default {
  name: "PostControlTabs",
  components: {
    "task-post-controls": () => import("@shared/tasks/posts/_postControls")
  },
  inject: ["$taskProvider"],
  props: {
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: 0
    };
  },
  computed: {
    $task() {
      return this.$taskProvider();
    },
    userIsReseller() {
      return this.$store.getters["user/isReseller"]();
    },
    userIsAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    tabs() {
      return [
        {
          filter: true,
          label: "Reply",
          props: {
            allowFiles: true
          }
        },
        {
          filter:
            this.$task.hasResellerOrigins &&
            (this.userIsReseller || this.userIsAgent),
          label: "Private message",
          props: {
            isPrivate: true,
            allowFiles: true
          }
        },
        {
          filter: this.userIsAgent,
          label: "Internal note",
          props: {
            isInternal: true,
            allowFiles: true
          }
        },
        {
          filter: this.userIsAgent,
          label: "Log entry",
          props: {
            isLog: true,
            allowFiles: false
          }
        }
      ];
    },
    filteredTabs() {
      return this.$_.filter(this.tabs, tab => tab.filter);
    }
  },
  watch: {
    activeTab() {
      this.$refs.taskPostControls.$refs.postField.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.task-post-control-tabs {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 1rem;
  @include mobile {
    grid-template-columns: 0 1fr;
    grid-column-gap: 0;
  }
  & > picture {
    grid-row: 2;
    grid-column: 1;
  }
  & .tabs {
    grid-row: 1;
    grid-column: 2;
    margin: 0;
    ul {
      border-bottom: none;
    }
    a {
      margin-bottom: 0;
      padding: 0.75em 1em;
    }
  }
  & .task-post-controls {
    grid-row: 2;
    grid-column: 2;
  }
}
</style>
